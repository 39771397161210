* {
  box-sizing: border-box;
}
html {
  touch-action: manipulation;
  font-size: 14px;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
div,
p,
a,
h1,
h2,
h3,
h4,
h5,
h6,
span {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
input {
  background: none;
  border: none;
  outline: none;
}
input::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #ccc;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  background: rgba(233, 233, 233, 0.1);
}
.link {
  cursor: pointer;
  color: #0057ff;
}
